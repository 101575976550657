<template>
    <div class="relative">
        <div v-if="user">
            <h3 class="mt-8 text-blue text-left"><b>Cambiar contraseña:</b></h3>
            <form>
                <BasePassword label="Actual contraseña" v-model="current_password" placeholder="Contraseña" />
                <BasePassword label="Nueva contraseña" v-model="newPassword" placeholder="Contraseña" :legend="true" />
                <BasePassword label="Verificar nueva contraseña" v-model="verifyPassword" :legend="true"
                    placeholder="Verifcar contraseña" />
                <p v-if="feedback" class="mt-4 text-xs text-red-500">{{ feedback }}</p>
                <a-button class="mt-5" type="primary" :block="true" :loading="loading" shape="round"
                    @click.native="changePassword">
                    CAMBIAR CONTRASEÑA
                </a-button>
            </form>
        </div>
        <div v-if="loading" class="spinner absolute w-full h-full top-0 flex items-center justify-center">
            <a-spin class="">
                <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
            </a-spin>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            current_password: '',
            newPassword: '',
            verifyPassword: '',
            feedback: '',
            loading: false,
        }

    },
    methods: {
        async changePassword() {
            this.loading=true
            let id = this.user.id;
            let form = {
                current_password: this.current_password,
                password: this.newPassword
            }

            if (this.$validator.isEmpty(this.current_password)) {
                this.feedback = 'Debe ingresar la contraseña actual';
                this.loading=false
                return;
            } else if (this.$validator.isEmpty(this.newPassword)) {
                this.feedback = 'Debe ingresar una nueva contraseña';
                this.loading=false
                return;
            } else if (this.newPassword != this.verifyPassword) {
                this.feedback = 'Las contraseñas no coinciden';
                this.loading=false
                return;
            } else if (!this.$validator.isStrongPassword(this.newPassword + '')) {
                this.feedback = 'La nueva contraseña no cumple los requisitos';
                this.loading=false
                return;
            } else {
                this.feedback = "";
            }

            let { data, error } = await this.$api.updateUserPassword(id, form);

            this.loading = false;

            if (error) {
                error.error == "La clave actual es incorrecta." ?
                    this.feedback = "La contraseña actual es inválida" :
                    this.feedback = "Error al actualizar la contraseña";
                // TO_DO Poner mensaje "La contraseña actual no es válida"
                this.$notification.error({
                    message: 'Notificación',
                    description: 'Error al actualizar la contraseña',
                });
            }
            if (data) {
                this.current_password = '';
                this.newPassword = '';
                this.verifyPassword = '';
                this.$notification.success({
                    message: 'Notificación',
                    description: 'Se actualizó correctamente la contraseña',
                });
            }
        },
    },
    computed: {
        user: function () {
            return this.$store.state.session.user;
        }
    }
}
</script>